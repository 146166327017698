/* Basic reset and app styling */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #1a1a1a; /* Dark background for contrast */
  color: white;
}
/* Processing state styling */
.processing {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff; /* White text for visibility */
}

.spinner {
  width: 20px;
  height: 20px;
  border: 4px solid #555555; /* Dark gray border */
  border-top: 4px solid #ffffff; /* White spinning part */
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-right: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

button {
  background-color: transparent;
  color: #ffffff;
  border: 2px solid #ffffff;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: rgba(255, 255, 255, 0.1); /* Subtle hover effect */
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Blur effect and disable interactions for main content */
.main-content.blurred {
  filter: blur(5px);
  pointer-events: none; /* Prevents clicks and interactions */
}

/* Overlay covering the entire screen */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent gray background */
  z-index: 1000; /* Ensures overlay is on top */
}

/* Popup styling */
.popup {
  background-color: #333333; /* Medium gray for monochrome */
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  text-align: center;
  color: #ffffff; /* White text for contrast */
  width: 350px;
  animation: fadeIn 0.5s ease-in-out; /* Smooth fade-in effect */
}

/* Heading and paragraph styles inside popup */
.popup h2 {
  font-size: 28px;
  margin-bottom: 15px;
}

.popup p {
  font-size: 18px;
  margin-bottom: 25px;
}

/* Processing state styling */
.processing {
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  width: 20px;
  height: 20px;
  border: 4px solid #555555; /* Darker gray for spinner */
  border-top: 4px solid #ffffff; /* White for spinning effect */
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-right: 10px;
}

/* Spinner animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Connect button styling (assumes WalletConnect uses this class) */
.connect-button {
  background-color: transparent;
  color: #ffffff;
  border: 2px solid #ffffff;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.connect-button:hover {
  background-color: rgba(255, 255, 255, 0.1); /* Subtle hover effect */
}

/* Fade-in animation for popup */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Responsive design for smaller screens */
@media (max-width: 600px) {
  .popup {
    width: 90%;
  }
}
.main-content.blurred {
  filter: blur(5px);
  pointer-events: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.popup {
  background-color: #333333;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  text-align: center;
  color: #ffffff;
  width: 350px;
}